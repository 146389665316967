

























import { Component, Vue, Prop } from "nuxt-property-decorator";
import striptags from "striptags";
import config from "@/config";

@Component<OProjectTeaser>({})
export default class OProjectTeaser extends Vue {
  baseUrl = config.cms.baseUrl;
  @Prop({ default: null }) project!: any;
  @Prop({ default: null }) page!: any;

  get lang() {
    return this.$i18n.locale;
  }

  getTitle(project: any) {
    let title = project.title ? striptags(project.title) : "";

    const highlight = project.title_highlight || null;

    if (highlight) {
      title = title.replace(
        highlight,
        `<span class="highlight">${highlight}</span>`
      );
    }

    return title;
  }
}
