import { render, staticRenderFns } from "./PageServices.vue?vue&type=template&id=63759a76&lang=pug&"
import script from "./PageServices.vue?vue&type=script&lang=ts&"
export * from "./PageServices.vue?vue&type=script&lang=ts&"
import style0 from "./PageServices.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageServicesItem: require('/var/www/imoli.app/components/Page/PageServices/PageServicesItem.vue').default,PageServicesDecoration: require('/var/www/imoli.app/components/Page/PageServices/PageServicesDecoration.vue').default})
