









import { Component, Vue } from "nuxt-property-decorator";

@Component<OServices>({})
export default class OServices extends Vue {
  services = [
    {
      type: "web-development",
      to: { name: "services-web-development" },
      name: "services.web.name",
      description: "services.web.description"
    },
    {
      type: "e-commerce",
      to: { name: "software-e-commerce" },
      name: "services.ecommerce.name",
      description: "services.ecommerce.description"
    },
    {
      type: "mobile-development",
      to: { name: "services-mobile-development" },
      name: "services.mobile.name",
      description: "services.mobile.description"
    },
    {
      type: "branding",
      to: { name: "services-design-workshops" },
      name: "services.design-workshops.name",
      description: "services.design-workshops.description"
    }
  ];
}
